import React from "react"
import { Link } from 'gatsby'

import Layout from "../components/layout"

const societalPage = () => (
  <Layout title='Societal'>
    <p>
      The Societal section of this blog looks at the rulesets that govern our systems. In my youth, I've often wondered what I can contribute to the conversation with my limited scope of experience. I've studied just a few slices of history and have personally experienced only one, so it feels a bit presumptuous to believe I have the answers to our problems.
      <br /><br />
      Even so, I imagine staying silent is unlikely to be one of those answers. I want to offer a voice both sound and specific that speaks to what I <i>do</i> know. In particular, I hope my understanding of <a href='https://www.investopedia.com/terms/m/mechanism-design-theory.asp'> mechanisms</a> can offer something close when sewn together with the patchwork of insights I've collected in the pursuit of personal interests.
      <br /><br />
      These writings focus on the components of our society I believe are due for change.
    </p>

    <br/><br/>
    <b><i>In the Works</i></b>
    <ul>
      <li><Link href="/societal/">Underlying Problems (editing)</Link></li>
      <li><Link href="/societal/">Incentive Alignment in Police Departments (drafting)</Link></li>
      <li><Link href="/societal/">A Capitalistic Society that Acknowledges Externalities (outlining)</Link></li>
    </ul>
  </Layout>
)

export default societalPage

